import { useState } from 'react';
import './App.css';

const SrpskiLatinica = (props) => {
    const [type, setType] = useState("export")
    const [code, setCode] = useState("")
    const [isSendingInProgres, setIsSendingInProgres] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [name, setName] = useState("")
    const [baseTariff, setBaseTariff] = useState("")
    const [category, setCategory] = useState("")
    const [years, setYears] = useState([])
    const [searchResults, setSearchResults] = useState([])

    const sendInquiry = (codeToSearch) => {
        setIsSendingInProgres(true)
        setSearchResults([])

        const formData = new FormData();

        formData.append('type', type);
        const codeTrimmed = codeToSearch.replace(/\D/g, '')
        formData.append('code', codeTrimmed);
        formData.append('page', "1");
        formData.append('offset', "1");

        fetch("https://pks.ohdstaging.com/public-api/china-tariffs", {
            // fetch("https://api.pks.rs/public-api/china-tariffs", {

            method: 'POST',
            headers: {
                "Accept": 'application/json',
            },
            body: formData,
        }).then((res) => res.json())
            .then((result) => {
                if (result.body.error_fields || result.body.entries.length === 0) {
                    setErrorMsg("Za uneti tarifni broj ne postoje podaci u bazi.")
                    setBaseTariff("")
                    setCategory("")
                    setYears([])
                    setName("")
                } else {
                    setErrorMsg("")
                    setBaseTariff(result.body.entries[0].base_tariff)
                    setCategory(result.body.entries[0].category)
                    setName(result.body.entries[0].name_sr_lat)
                    setCode(result.body.entries[0].code)
                    const yearValues = []
                    const resultArray = (Object.entries(result.body.entries[0]))
                    for (let i = 6; i < resultArray.length; i++) {
                        yearValues.push(resultArray[i])
                    }
                    setYears(yearValues)
                }
                setIsSendingInProgres(false)
            }).catch((error) => {
                setIsSendingInProgres(false)
            });
    }
    const sendSearchInquiry = (value) => {
        setBaseTariff("")
        setCategory("")
        setYears([])
        setName("")
        const formData = new FormData();
        formData.append('type', type);
        const codeTrimmed = value.replace(/\D/g, '')
        formData.append('code', codeTrimmed);
        formData.append('page', "1");
        formData.append('offset', "1000");
        fetch("https://pks.ohdstaging.com/public-api/china-tariffs", {
            // fetch("https://api.pks.rs/public-api/china-tariffs", {

            method: 'POST',
            headers: {
                "Accept": 'application/json',
            },
            body: formData,
        }).then((res) => res.json())
            .then((result) => {
                const data = []
                for (let i = 0; i < result.body.entries.length; i++) {
                    data.push(result.body.entries[i])
                }
                setSearchResults(data)
            })
    }
    const handleChange = (event) => {
        if (event.key === 'Enter') {
            sendInquiry(code)
        }
    }

    const checkSendingSearch = (value) => {
        setCode(value)
        if (value.length === 4 || value.length === 6 || value.length === 8) {
            sendSearchInquiry(value)
        } else if (value.length < 4) {
            setSearchResults([])
        }
    }

    const mapSearchResults = () => {
        return searchResults.map((result, i) => {
            return <li key={i} onClick={() => { setCode(result.code); sendInquiry(result.code); setSearchResults([]) }} className='search-result-list-item'><span className='search-result-code'>{result.code}</span><span className='list-item-limited'>{result.name_sr_lat}</span></li>
        })
    }
    const yearsToDisplay = ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038"]

    return (
        <main>
            <div className='main-title-wrapper'>
                <h1>Carinske stope na trgovinu proizvodima<br />
                    prema Sporazumu o slobodnoj trgovini<br />
                    između Republike Srbije i Narodne Republike Kine</h1>
            </div>
            <div className='text-wrapper' dangerouslySetInnerHTML={{ __html: props.tekst_iznad }} />
            <div className='form-wrapper'>
                <label>Odaberite</label>
                <select onChange={(event) => setType(event.target.value)}>
                    <option value="export">Izvoz iz Srbije u Kinu</option>
                    <option value="import">Uvoz u Srbiju iz Kine</option>
                </select>
                <div className='search-wrapper'>
                    <label>Unesite traifni broj</label>
                    <input className={errorMsg && "error-input"} type="text" value={code} onChange={(event) => checkSendingSearch(event.target.value)} onKeyDown={(event) => handleChange(event)} />
                    {/* <p className='disclaimer-msg'>{type === "export" ? "унесите тарифни број од 8 цифара" : "унесите тарифни број од 10 цифара"}</p> */}
                    <p className='error-msg'>{errorMsg}</p>
                    {searchResults.length > 0 &&
                        <ul className='search-results-wrapper'>
                            {mapSearchResults()}
                        </ul>
                    }
                </div>
                {!isSendingInProgres &&
                    <button onClick={() => sendInquiry(code)}>
                        Pretraži
                    </button>
                }
                {isSendingInProgres &&
                    <button className='disabled-btn'>
                        Pretraga u toku
                    </button>
                }


                <ul className='result-list'>
                    {name &&
                        <li className='result-list-item'><span className='list-item'>Naziv</span> <span className='list-item list-item-limited'>{name}</span></li>
                    }
                    {baseTariff &&
                        <li className='result-list-item'><span className='list-item'>Bazna carina %</span> <span className='list-item'>{baseTariff}</span></li>
                    }
                    {category &&
                        <li className='result-list-item'><span className='list-item'>Kategorija</span> <span className='list-item'>{category}</span></li>
                    }
                    {years &&
                        years.map((year, i) => {
                            if (year) {
                                return <li className='result-list-item' key={i}><span className='list-item'>Godina {yearsToDisplay[year[0].split("_")[1] - 1]} (%)</span> <span className='list-item'>{year[1].length !== 0 ? year[1] : "/"}</span></li>
                            } else {
                                return <li className='result-list-item' key={i}><span className='list-item'>Godina {yearsToDisplay[year[0].split("_")[1] - 1]} (%)</span> <span className='list-item'>{parseFloat(year[1]).toFixed(1).length !== 0 ? parseFloat(year[1]).toFixed(1) : "/"}</span></li>

                            }
                        })
                    }
                </ul>
            </div>
            <div className='text-wrapper' dangerouslySetInnerHTML={{ __html: props.tekst_ispod }} />
        </main >
    );
}

export default SrpskiLatinica;
